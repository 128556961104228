@import url(https://fonts.googleapis.com/css2?family=Montserrat:wght@300&display=swap);
html,
body {
  font-family: "Montserrat";
}
.container2 {
  margin: -8px -8px -8px -8px;
  overflow-x: hidden;
  overflow-y: hidden;
}
.container {
  margin: 63.4px -8px -8px -8px;
  overflow-x: hidden;
  overflow-y: hidden;
}
a {
  text-decoration: none;
}

.navbar__link {
  font-size: 0.95em;
  text-decoration: none;
  text-indent: 0.3em;
  color: #f6fff8;
  font-weight: 900;
  height: 18px;
  padding: 5.5px 9px 7px 9px;
  text-align: "center";
  font-family: "Montserrat";
}
.navbar__link--active {
  width: 100%;
  color: #09bc8a !important;
  transition: border-bottom 0.5s ease-in-out;
}
.navbar__link:hover {
  color: #ec8f56;
}

.prj-two p {
  font-weight: bold;
  font-size: 22px !important;
  line-height: 22px;
  padding-bottom: 17px;
}
.prj-two h4 {
  font-weight: bold;
  padding-top: 3%;
  font-size: 40px;
  line-height: 50px;
  padding-bottom: 10px;
}
.project-1:hover .prj-1-content {
  opacity: 1;
  left: 0px;
}
.prj-1-content {
  background: #0b2239;
  /* color: #0b3765; */
  font-family: "Montserrat";
  text-align: center;
  z-index: 1;
  padding: 30px;
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: -100%;
  /* height: calc(100% - 291px); */
  width: calc(100% - 40px);
  opacity: 0;
  transition: 0.5s ease;
}

@media only screen and (max-width: 600px) {
  .prj-1-content {
    height: calc(100% - 292px) !important;
  }
}
@media only screen and (max-width: 415px) {
  .prj-1-content {
    height: calc(100% - 293px) !important;
  }
}
@media only screen and (max-width: 281px) {
  .prj-1-content {
    height: calc(100% - 283px) !important;
  }
}

@media only screen and (max-width: 1120px) {
  .navbar__link {
    font-size: 13px;
  }
}
@media only screen and (max-width: 939px) {
  .navbar__link {
    font-size: 13px;
  }
}
@media only screen and (max-width: 875px) {
  .navbar__link {
    font-size: 12px;
  }
}

@media only screen and (max-width: 600px) {
  .navbar__link {
    width: 100%;
    color: #f6fff8 !important;
  }
  .navbar__link:hover {
    color: #09bc8a !important;
  }
}

.navbar {
  background-color: #060b26;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: start;
          justify-content: start;
  -webkit-align-items: center;
          align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: start;
          justify-content: start;
  -webkit-align-items: center;
          align-items: center;
  padding: 4px 0px 4px 16px;
  list-style: none;
  height: 40px;
  margin-left: -40;
  /* font-size: 12px; */
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 15px;
  font-weight: bold;
  width: 95%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  margin-left: -16px;
}

.navbar__link2 {
  text-decoration: none;
  /* background-color: #56eca6; */
}
.navbar__link2--active {
  width: 100%;
  color: #00ccff !important;
  transition: border-bottom 0.5s ease-in-out;
}

.nav-text a:hover {
  background-color: #ec8f56;
  color: #0b2239;
  width: 100%;
}

.nav-menu-items {
  margin-left: -40px;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: start;
          justify-content: start;
  -webkit-align-items: center;
          align-items: center;
}

span {
  margin-left: 16px;
}

@media only screen and (max-width: 600px) {
  .nav-text {
    padding: 4px 0px 0px 16px;
  }
}

