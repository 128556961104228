.navbar {
  background-color: #060b26;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

.menu-bars {
  margin-left: 2rem;
  font-size: 2rem;
  background: none;
}

.nav-menu {
  background-color: #060b26;
  width: 250px;
  height: 100vh;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: -100%;
  transition: 850ms;
}

.nav-menu.active {
  left: 0;
  transition: 350ms;
}

.nav-text {
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 4px 0px 4px 16px;
  list-style: none;
  height: 40px;
  margin-left: -40;
  /* font-size: 12px; */
}

.nav-text a {
  text-decoration: none;
  color: #f5f5f5;
  font-size: 15px;
  font-weight: bold;
  width: 95%;
  height: 100%;
  display: flex;
  align-items: center;
  padding: 0 16px;
  border-radius: 4px;
  margin-left: -16px;
}

.navbar__link2 {
  text-decoration: none;
  /* background-color: #56eca6; */
}
.navbar__link2--active {
  width: 100%;
  color: #00ccff !important;
  transition: border-bottom 0.5s ease-in-out;
}

.nav-text a:hover {
  background-color: #ec8f56;
  color: #0b2239;
  width: 100%;
}

.nav-menu-items {
  margin-left: -40px;
}

.navbar-toggle {
  background-color: #060b26;
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: start;
  align-items: center;
}

span {
  margin-left: 16px;
}

@media only screen and (max-width: 600px) {
  .nav-text {
    padding: 4px 0px 0px 16px;
  }
}
